@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Lato", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.cls-2 {
  clip-path: url(#clip-path);
}
